<template>
  <!--begin::Staff Listing-->
  <div class="engineer-template">
    <PageHeaderCount
      moduleType="product"
      :dataLoading="dataLoading"
      :statusList="statusList"
      :extraList="topExtraList"
      allkey="all_products"
      countkey="product_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'engineer-listing'"
      v-if="getPermission('engineer:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('product:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="productBulkAction(item.status)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="5" class="pt-0">
                <v-layout class="w-100">
                  <v-flex md6>
                    <h1 class="form-title d-flex margin-auto">All Teams</h1>
                  </v-flex>
                </v-layout>
              </v-col>

              <ManageTeamPopup
                :dialog="manageBrandDialog"
                :e_designation="engineerDesignations"
                :updatePopup="teamManage"
                v-on:refresh="getTeams()"
                v-on:close="manageBrandDialog = false"
              ></ManageTeamPopup>

              <v-col cols="7" class="pt-0 justify-flex-end d-flex margin-auto">
                <v-flex md="2" class="pb-2 mr-2 mx-width-400">
                  <v-text-field
                    v-model.trim="filter_search"
                    v-on:click:clear="clearFilter('search')"
                    clearable
                    dense
                    filled
                    flat
                    label="Search"
                    hide-details
                    solo
                    @keydown.enter="getTeams()"
                    @keydown.tab="getTeams()"
                    v-on:change="getTeams()"
                    color="cyan"
                  ></v-text-field>
                </v-flex>
                <template>
                  <v-btn
                    v-on:click="getTeams()"
                    class="mr-4"
                    color="cyan white--text"
                    >Search</v-btn
                  >
                </template>
                <template>
                  <v-btn
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    v-on:click="manageBrandDialog = true"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <!-- <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-on:change="updateColumns(cols.field, $event)"
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu> -->
                <!--  <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>    -->
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Product"
          :basicSearchFields="['name', 'barcode', 'reference']"
          :allowedSearchFields="searchableArray"
        >
        </ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow custom-team-listing"
          style="position: relative"
        >
          <ListingTable
            :dataLoading="dataLoading"
            :columnCount="columnLength()"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in defaultColDefs">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody>
                <template v-if="teamList.length > 0">
                  <template v-for="(teamRow, index) in teamList">
                    <tr :key="index" v-on:click="viewClicked(teamRow)"
                    class="customer-listing-row"
                  
                    >
                      <td class="align-middle">
                        <template v-if="teamRow && teamRow.barcode">
                          <!-- <v-tooltip top content-class="custom-top-tooltip"> -->
                            <template>
                              <v-icon
                                v-on:click.stop.prevent="updateTeam(teamRow.id)"
                                color="cyan"
                                class="customBtn"
                                style="color: white !important"
                                >mdi-pencil</v-icon
                              >
                            </template>
                        <!--     <span>Click here to update</span> -->
                    <!--       </v-tooltip> -->
                          <!--    <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-on:click="viewClicked(teamRow)"
                                color="cyan"
                                v-bind="attrs"
                                v-on="on"
                                style="color: white !important;"
                                class="customBtnView"
                                >mdi-eye</v-icon
                              >
                            </template>
                            <span>Click here to view</span>
                          </v-tooltip> -->
                          <!-- <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                depressed
                                fab
                                dark
                                x-small
                                color="cyan"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                                v-on:click="updateTeam(teamRow.id)"
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                depressed
                                fab
                                dark
                                x-small
                                color="cyan"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                                v-on:click="viewClicked(teamRow)"
                              >
                                <v-icon>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span>View</span>
                          </v-tooltip> -->
                          <!--     <v-btn
                            rounded
                            :disabled="dataLoading"
                            class="custom-bold-button"
                            color="cyan white--text"
                            v-on:click="viewClicked(teamRow)"
                          >
                          <v-icon>mdi-pencil</v-icon>
                          </v-btn> -->
                        </template>
                      </td>
                      <td class="align-middle">
                        <template v-if="teamRow && teamRow.barcode">
                          <Barcode :barcode="teamRow.barcode"></Barcode>
                        </template>
                      </td>
                      <td class="align-middle">
                        <template>
                          <div class="engineer-listing">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Team Name: </b>
                              {{ teamRow.team_name }}
                            </p>
                          </div>
                        </template>
                      </td>

                      <td>
                        <template>
                          <div class="engineer-listing">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <!-- <template
                                v-if="
                                  staffdisplayName(teamRow.team_id).length > 3
                                "
                              >
                                {{
                                  `${staffdisplayName(teamRow.team_id)[0]}, ${
                                    staffdisplayName(teamRow.team_id)[1]
                                  }, ${staffdisplayName(teamRow.team_id)[2]}`
                                }}
                                <template
                                  v-for="(teamdata, indexd) in teamRow.team_id"
                                >
                                  <span
                                    class="cursor-pointer"
                                    :key="indexd"
                                    v-on:click="
                                      checkTeamDetails(teamdata.team_id)
                                    "
                                  >
                                    <template v-if="indexd == 0">
                                      more...
                                    </template>
                                  </span>
                                </template>
                              </template> -->
                              <template>
                                <div
                                  class="d-flex flex-wrap"
                                  style="max-width: 150px; min-width: 150px"
                                >
                                  <template
                                    v-for="(item, index) in teamRow.team_id"
                                  >
                                    <v-chip
                                      :key="index"
                                      class="font-weight-600 mr-1 mb-1"
                                      color="chip-custom-blue"
                                      label
                                      small
                                      outlined
                                      v-if="item && item.staff_id && item.staff_id.display_name"
                                    >
                                      {{ item.staff_id.display_name }}
                                    </v-chip>
                                  </template>
                                </div>

                                <!-- <v-chip
                                class="ma-2 font-weight-600"
                                color="chip-custom-blue"
                                label
                                small
                                outlined
                              >
                              {{ `${staffdisplayName(teamRow.team_id)}` }}
                              </v-chip> -->
                                <!--  {{ `${staffdisplayName(teamRow.team_id)}` }} -->
                              </template>
                            </p>
                          </div>
                        </template>
                      </td>

                      <td>
                        <div class="engineer-listing">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Open: </b>
                            <template v-if="teamRow.total_open"
                              ><span
                                class="ml-1 count-radius blue text-white"
                                >{{ teamRow.total_open }}</span
                              ></template
                            >
                            <template v-else
                              ><em class="text--secondary"
                                >no open visits</em
                              ></template
                            >
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Closed: </b>
                            <template v-if="teamRow.total_complete"
                              ><span class="ml-1 count-radius red text-white">{{
                                teamRow.total_complete
                              }}</span></template
                            >
                            <template v-else
                              ><em class="text--secondary"
                                >no closed visits</em
                              ></template
                            >
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Overdue: </b>
                            <template v-if="teamRow.total_overdue"
                              ><span
                                class="ml-1 count-radius cyan text-white"
                                >{{ teamRow.total_overdue }}</span
                              ></template
                            >
                            <template v-else
                              ><em class="text--secondary"
                                >no overdue visits</em
                              ></template
                            >
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>In-Progress: </b>
                            <template v-if="teamRow.total_in_progress"
                              ><span class="ml-1 count-radius yellow">{{
                                teamRow.total_in_progress
                              }}</span></template
                            >
                            <template v-else
                              ><em class="text--secondary"
                                >no in-progress visits</em
                              ></template
                            >
                          </p>
                        </div>
                      </td>

                      <td>
                        <TableActivity
                          v-if="!lodash.isEmpty(teamRow.added_by)"
                          :data="teamRow"
                        >
                          <template v-slot:display_name>
                            {{ teamRow.added_by.display_name }}
                          </template>
                          <!--  -->
                        </TableActivity>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="mx-2 my-1 custom-grey-border"
                              color="blue-grey darken-1"
                              text-color="white"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-avatar left>
                                <v-icon>mdi-clock-outline</v-icon>
                              </v-avatar>
                              <p class="m-0 custom-nowrap-ellipsis">
                                {{ formatDateSartTime(teamRow.added_at) }}
                              </p>
                            </v-chip>
                          </template>
                          <p class="m-0 custom-nowrap-ellipsis">
                            {{ formatDateTime(teamRow.added_at) }}
                          </p>
                        </v-tooltip>
                        <template>
                          <!-- <v-btn
                            rounded
                            :disabled="dataLoading"
                            class="custom-bold-button"
                            color="cyan white--text"
                            v-on:click="viewClicked(teamRow)"
                          >
                            View
                          </v-btn> -->
                        </template>
                        <pre></pre>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else-if="!dataLoading">
                  <tr>
                    <td :colspan="columnLength()" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no team at the moment.
                      </p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Staff Listing-->
</template>

<script>
//import draggable from "vuedraggable";
import moment from "moment-timezone";
import { QUERY, GET } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import ManageTeamPopup from "@/view/pages/partials/Manage-team.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";

import { map } from "lodash";
//import draggable from "vuedraggable";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "team-list",
  mixins: [CommonMixin],
  components: {
    ListingFooter,
    ListingHeader,
    ListingTemplate,
    ListingSearchCriteria,
    PageHeaderCount,
    ManageTeamPopup,
    Barcode,
    TableActivity,
    //draggable,
    ListingTable,
  },
  data() {
    return {
      manageBrandDialog: false,
      engineerDesignations: [],
      engbarcode: "Eng-0014",
      routeDetailName: "purchase.order.detail",
      selectedRows: [],
      teamList: [],

      moreActions: [],
      dataLoading: false,
      importDialog: false,
      topExtraList: [],
      exportLoading: false,
      defaultColShow: [],
      totalRows: 0,
      rowsOffset: 0,
      currentPage: 1,
      totalPages: 0,
      status: "all",
      searchDialog: false,
      searchEnabled: false,
      searchableArray: [],
      perPage: 15,
      pageLoading: false,
      contractorLoading: false,
      propertyLoading: false,
      contractorDialog: false,
      projectDialog: false,
      engineerLoading: false,
      isRowLoading: {},
      dates: [],
      statusList: [],
      customerList: [],
      propertyList: [],
      teamManage: {},

      teamRow: [],
      contractorList: [],
      filter_search: null,
      filter_contractor: null,
      filter_engineer: null,
      filter_quotation: null,
      filter_invoice: null,
      filter_status: null,
      filter_property: null,
      timeout: null,
      timeoutLimit: 500,
      entity: 0,
      customer: 0,
      contractor: 0,
      project: 0,
      customerPerson: 0,
      customerProperty: 0,
      entityList: [],
      entityObject: {},
      deleteVisitId: null,
      deleteVisitDialog: false,
      deleteVisitLoading: false,
      deleteVisitBarcode: null,
    };
  },
  methods: {
    columnLength() {
      const defaultColDefs = this.lodash.filter(this.defaultColDefs, {
        visible: true,
      });
      //console.log(defaultColDefs);
      if (defaultColDefs) {
        return defaultColDefs.length;
      }
      return 0;
    },
    formatDateSartTime(row) {
      return moment(row).format("hh:mm A");
    },
    updateTeam(teamRow) {
      const _this = this;
      _this.pageLoading = true;

      _this.$store
        .dispatch(GET, {
          url: "team/" + teamRow,
        })
        .then(({ data }) => {
          this.manageBrandDialog = true;
          _this.teamManage = data;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter_search = null;
          break;
      }
      _this.getTeams();
    },

    getTeams() {
      const _this = this;

      _this.teamRow = [];

      _this.pageLoading = true;
      let current_page = _this.currentPage;
      let per_page = _this.perPage;
      let filterData = {
        search: _this.lodash.toString(_this.filter_search) || null,
        current_page,
        per_page,
      };
      _this.getteam(filterData);
    },
    getteam(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "team",
          data,
        })
        .then(({ data }) => {
          _this.teamList = data.rows;
          //  console.log(_this.teamList, "_this.teamList");

          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },

    /* 
 staffdisplayName(data) {
      let display_name = map(data, (row) => row.staff_id.display_name);
      console.log(display_name.length);
      if (display_name && display_name.length > 4) {
        return display_name.join(", ");
      }
      return "";
    }, */
    /* staffdisplayName(data) {
      let display_name = map(data, (row) => row.staff_id.display_name);
      if (display_name && display_name.length > 3) {
        let newName = [];
        newName[] = display_name[0];
        newName[] = display_name[1];
        newName[] = display_name[2];
        return newName.join(", ");
      }
      else
      {
        return display_name.join(", ");
      }
    }, */
    staffdisplayName(data) {
      //console.log(data,"data")
      if(data){
        let display_name = map(data, (row) => row.staff_id.display_name);
      return display_name;
      }
      
      /* if (display_name && display_name.length > 3) {
        return `${display_name[0]}, ${display_name[1]}, ${display_name[2]} more...`;
      }
      else
      {
        return display_name.join(", ");
      } */
    },

    checkTeamDetails(param) {
      if (param) {
        this.$router.push(
          this.getDefaultRoute("team.detailmember", {
            params: {
              teamId: param,
              tab: "engineer-info",
            },
          })
        );
      }

      //  console.log(param,"param");
    },

    viewClicked(teamRow) {
      let engineerdata = teamRow.team_id;
      let staffRow = map(engineerdata, (row) => row.staff_id.id);

      //  console.log(staffRow,"teamRowbbb");
      if (engineerdata) {
        this.$router.push(
          this.getDefaultRoute("team.detailmember", {
            params: {
              id: staffRow,
              teamId: teamRow.id,
            },
          })
        );
      } /* else {
        this.$router.push(
          this.getDefaultRoute("team.detailmember", {
            params: { id: teamRow.supervisor.id, teamId: teamRow.id },
          })
        );
      } */
    },
    clearSelections() {
      this.selectedRows = [];
    },
    updateColumns(field) {
      const index = this.lodash.findIndex(this.defaultColDefs, { field });
      // console.log({ index });
      this.defaultColDefs[index].visible = !this.defaultColDefs[index].visible;
      // console.log({ defaultColDefs: this.defaultColDefs });
    },
  },
  mounted() {
    this.getTeams();

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Team",
      },
    ]);
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.teamList) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      let teamRow = this.lodash.map(this.teamList, function (row) {
        if (!row.header) return row;
      });

      teamRow = this.lodash.without(teamRow, undefined);

      return this.rowsOffset + (teamRow ? teamRow.length : 0);
    },
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.teamRow.length) {
          for (let i = this.teamRow.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.teamRow[i].id);
          }
        }
      },
      get() {
        let totalItems = this.teamRow.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    },
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "Action",
        field: "action",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Team #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
        width: "200px",
      },

      {
        headerName: "Team's Info",
        field: "team_info",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
        width: "300px",
      },
      /*  {
        headerName: "Technician #",
        field: "staff_barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "200px",
      }, */
      {
        headerName: "Technicians's Info",
        field: "staff_info",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
        width: "300px",
      },
      {
        headerName: "Visit Count",
        field: "tickets",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
        width: "200px",
      },
      /*  {
        headerName: "View",
        field: "view",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
        width: "200px",
      }, */
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
      },
    ];

    const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true,
    });

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    // _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
  created() {
    const _this = this;

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.getTeams();
    });

    PaginationEventBus.$on("update:per-page", (param) => {
      _this.perPage = param;
    });
  },
};
</script>
<style scoped>
.barcode {
  font-size: 14px;
}

.v-application p {
  margin-bottom: 16px;
  font-size: 14px;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #ffffff !important;
}
</style>
